<template>
  <div>
    <!-- enter pin modal -->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      title="Enter Transaction Pin"
      size="sm"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Enter Transaction Pin</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <CForm>
        <CCardBody>
          <CInput
            placeholder="Transaction Pin"
            type="password"
            v-model="pin"
            label="Transaction Pin"
            required
          >
          </CInput>
        </CCardBody>
      </CForm>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Go Back</CButton>
        <CButton
          color="primary"
          @click="processTransaction"
          :disabled="!formIsValid || processLoading"
        >
          <span v-if="processLoading">
            <loader-icon size="1.5x" class="custom-class"></loader-icon>
            loading...
          </span>
          <span v-else>Pay</span>
        </CButton>
      </template>
    </CModal>

    <!-- main body -->
    <CRow>
      <CCol lg="9">
        <CCard>
          <CCardHeader>
            <strong class="h5">Transaction Details</strong>
            <p>
              The Details of your transaction...
            </p>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol
                    v-for="(item, index) in readables"
                    :key="index"
                    class="pointer col-12"
                    v-show="item.display === true"
                    md="6"
                  >
                    <P class="text font-weight-bolder">{{ item.label }}</P>
                    <p
                      v-html="item.value"
                      class="text font-weight-normal font-italic"
                    ></p>
                  </CCol>
                </CRow>
                <CButton
                  v-if="showButton"
                  color="primary"
                  class="px-4 float-right"
                  @click="darkModal = true"
                  size="lg"
                >
                  <span>Continue</span>
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/services/api.service";
// import ErrorService from "@/services/error.js";
export default {
  name: "ProcessTransaction",
  data() {
    return {
      darkModal: false,
      processLoading: false,
      showButton: true,
      transaction_data: "",
      pin: "",
      readables: [],
    };
  },
  created() {},
  computed: {
    formIsValid() {
      return this.pin !== "";
    },
    ...mapGetters(["userObj", "transactionObject"]),
    pinSetup() {
      return this.userObj.pin_setup === "no";
    },
    isUserTokenNull() {
      return this.$store.state.accessToken;
    },
  },
  methods: {
    async processTransaction() {
      let data = {
        reference: this.transactionObject.transaction.reference,
        pin: this.pin,
      };

      this.processLoading = true;
      try {
        const response = await ApiService.post(
          "/transaction/process-transaction",
          data
        );
        console.log(response);
        this.processLoading = false;
        this.transaction_data = response.data.data;
        this.darkModal = false;
        Swal.fire({
          title: "Success",
          text: response.data.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.showButton = false;
        this.readables = response.data.data.readable;
        this.pin = "";
        // setTimeout(() => this.$router.push({ name: "Transactions" }), 3000);
      } catch (error) {
        console.log(error.response);
        this.darkModal = false;
        this.processLoading = false;
        this.pin = "";

        let _error = error.response.data;
        Swal.fire({
          title: "Error!",
          text: _error.response_message,
          icon: "error",
          confirmButtonText: "Close",
        });
        // ErrorService.displayErrorAlert(_error);
      } finally {
        this.processLoading = false;
      }
    },
  },
  mounted() {
    this.readables = this.transactionObject.readable;
  },
};
</script>

<style scoped></style>
